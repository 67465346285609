<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
    <path
      d="M7.583 4.2574C6.31902 4.23493 5.05466 4.26362 3.793 4.3434C2.829 4.4034 2.063 5.1714 1.961 6.1324C1.852 7.1424 1.75 8.1834 1.75 9.2494C1.75 10.3154 1.852 11.3574 1.96 12.3664C2.064 13.3264 2.83 14.0964 3.793 14.1564C5.793 14.2804 7.706 14.2804 9.707 14.1564C10.671 14.0964 11.437 13.3264 11.539 12.3664C11.648 11.3564 11.75 10.3154 11.75 9.2494C11.75 8.9694 11.743 8.6924 11.73 8.4164M8.25 7.68348L14.003 1.92948M14.0287 5.98348C14.1897 4.36348 14.1897 3.47348 14.0287 1.92348C12.4787 1.76248 11.5888 1.76248 9.96875 1.92348"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
